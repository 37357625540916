export const environment = {
  production: false,
  svAPIKey: "JLV321",
  baseUrl: "https://staging.public.api.lafka.dev/api/v1",
  googleAppId: "",
  facebookAppId: "",
  firebase: {
    apiKey: "AIzaSyDMS70hVrbWgJ0hlw3fp4Ore_u9FXwY9y4",
    authDomain: "jaloviini-staging.firebaseapp.com",
    projectId: "jaloviini-staging",
    storageBucket: "jaloviini-staging.appspot.com",
    messagingSenderId: "85915733972",
    appId: "1:85915733972:web:9d11076d16f6e1b1065041",
    measurementId: "G-X25BYQVTKE",
  },
};
