import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { IModalObject } from "../ITypes";
import { GeneralService, ShopService, UserService } from "../services";
import {
  AuthService,
  CartService,
  SiteConfigService,
  CacheService,
  MyCookieService,
  AnalyticsService,
  CentralStorageService,
} from "src/app/core/services";
import {
  IUserLoginCredentails,
  ILoginErrors,
  IConfig,
} from "src/app/core/ITypes";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { AngularFireAnalytics } from "@angular/fire/analytics";

declare const initAnalytics;

@Component({
  selector: "sv-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
  constructor(
    public generalService: GeneralService,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private cartService: CartService,
    public siteConfigService: SiteConfigService,
    private translateService: TranslateService,
    private cacheService: CacheService,
    private cookieService: MyCookieService,
    private afAnalytics: AngularFireAnalytics,
    private shopService: ShopService,
    public translate: TranslateService,
    private analyticsService: AnalyticsService,
    private centralStorageService: CentralStorageService
  ) {}
  modal: IModalObject = { id: "", name: "", isOpen: false };
  modalOpen: boolean = false;
  forgotPasswordEmail: string = "";
  resetPassword: string = "";
  confirmPassword: string = "";
  modalName: string = "";
  parentCompany: string = "";
  deliveryAddress: any = null;
  //^ name various modals in the html, check and show whichever choosen in service
  // current names include : 'signUpModal', 'signInModal', 'signOutModal'

  interactionsDisabled: boolean = false;
  authObj: IUserLoginCredentails = {
    name: "",
    lastName: "",
    pass: "",
    email: "",
    location: "",
    phone: "",
    birthDate: "",
  };
  errors: ILoginErrors = {
    loginError: "",
    signUpError: "",
    socialLoginError: "",
  };
  signupBtnText: string = "Sign up";
  signUpTermsAccepted: boolean = false;
  defaultForgotPasswordMethod: string = "email";
  isBirthDateField: boolean = false;
  isBackgroundDarker: boolean = false;
  logo1: IConfig["navbar"]["logo1"] = {
    src: "",
    label: "",
  };
  MODAL_NAME_SUCCESSFUL_PAYMENT = "paymentSuccessful";
  MODAL_NAME_AGE_CONFIRMATION = "ageConfirmationPopupModal";
  MODAL_NAME_DELIVERY_ADDRESS = "deliveryAddressModal";
  MODAL_NAME_SIGNUP = "signUpModal";

  private unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.generalService.observeModalState
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((modal) => {
        this.modalOpen = modal.isOpen;
        this.modalName = modal.name;
        this.modal = modal;

        if (modal.id === this.MODAL_NAME_DELIVERY_ADDRESS) {
          this.deliveryAddress = modal.data;
          this.deliveryAddress.shipping_country = "Finland";
        } else if (modal.id === this.MODAL_NAME_AGE_CONFIRMATION) {
          this.isBackgroundDarker = true;
        }
      });

    let lang = this.translateService.getBrowserLang();
    this.parentCompany =
      this.siteConfigService.siteConfig.parentCompany[`${lang}`];
    this.isBirthDateField =
      this.siteConfigService.siteConfig.signup.isBirthDateField;
    this.authObj = this.authService.authObj;
    const { navbar } = this.siteConfigService.siteConfig;
    this.logo1 = navbar.logo1;
  }

  closeModal() {
    if (this.modalName === this.MODAL_NAME_SUCCESSFUL_PAYMENT) {
      this.router.navigate(["/"]);
    }
    this.generalService.setModalState = { ...this.modal, isOpen: false };
  }

  async logOut() {
    // assign new uuid
    this.generalService.setCartPanelState = false;
    await this.authService.signOut();

    let uuid = this.generalService.generateUUID;
    this.userService.setUserUUID = uuid;
    // await this.cartService.removeCart();

    let { cart } = this.cartService;

    if (cart && cart.id) {
      await this.shopService.updateCart(
        cart.id,
        {
          user_uuid: uuid,
        },
        {
          user_uuid: uuid,
        }
      );
    }

    this.closeModal();

    await this.cacheService.remove("preference");
    await this.centralStorageService.resetAllCentralStoredKeys();

    document.location.href = "/";
  }

  async signUpViaEmail() {
    try {
      this.userService.setUserUUID = this.generalService.generateUUID;
      // Validation
      const { name, lastName, email, pass, phone, birthDate } = this.authObj;
      let missingField = null;
      if (!name || !lastName || !email || !pass || !phone) {
        missingField = !pass ? "Password" : "";
        missingField = !email ? "Email" : missingField;
        missingField = !lastName ? "Last Name" : missingField;
        missingField = !name ? "First Name" : missingField;
        missingField = !phone ? "Phone" : missingField;
      }
      if (this.isBirthDateField) {
        if (this.validateAge(birthDate)) {
          this.errors.signUpError = this.translate.instant("GLOBAL.G054.value");
          return;
        }
        missingField = !birthDate ? "Birth Date" : missingField;
      }
      this.errors.signUpError = missingField && `${missingField} is required`;

      this.interactionsDisabled = true;
      this.signupBtnText = "Registering...";
      this.authService.authObj = this.authObj;

      let response = await this.authService.signUp(this.authObj);
      if (response) {
        let { message, code, user } = response;

        this.signupBtnText = "Sign up";
        this.interactionsDisabled = false;

        if (message && code) {
          this.errors.signUpError = message;
          if (code === "auth/email-already-in-use") {
            this.errors.signUpError = this.translate.instant("AUTH.A025.value");
          }
          if (code === "auth/weak-password") {
            this.errors.signUpError = this.translate.instant("AUTH.A024.value");
          }
          if (code === "auth/invalid-email") {
            this.errors.signUpError =
              this.translate.instant("GLOBAL.G032.value") +
              " " +
              this.translate.instant("GLOBAL.G008.value");
          }
        } else if (user && user.email) {
          this.generalService.setModalState = {
            ...this.generalService.getModalState,
            isOpen: false,
          };

          this.analyticsService.signupEvent();

          let document = this.generalService.getDocument;
          setTimeout(() => {
            document.location.href = document.location.href;
          }, 3000);
        }
        return response;
      }
    } catch (error) {
      this.signupBtnText = "Sign up";
      this.interactionsDisabled = false;

      this.errors.signUpError = error;
      console.error("sign up error >>> ", error);
    }
  }

  acceptDeliveryTerms(event) {
    this.signUpTermsAccepted = event.target.checked;
  }

  handleForgotPasswordMethod(method: string) {
    this.defaultForgotPasswordMethod = method;
  }

  async handleForgotPassword() {
    try {
      if (!this.forgotPasswordEmail) {
        return;
      }
      const res = await this.authService.forgotPassword(
        this.forgotPasswordEmail,
        this.defaultForgotPasswordMethod
      );
      if (res) {
        this.forgotPasswordEmail = "";
        this.generalService.setModalState = {
          id: "4",
          name: "forgotPasswordModal",
          isOpen: false,
        };
      }
    } catch (error) {
      console.log("error in handleForgotPassword: ", error);
    }
  }

  async handleResetPassword() {
    try {
      if (
        !this.resetPassword ||
        !this.modal.oobCode ||
        this.resetPassword != this.confirmPassword
      ) {
        return;
      }
      const res = await this.authService.resetPassword(
        this.resetPassword,
        this.modal.oobCode
      );
      if (res) {
        this.resetPassword = "";
        this.modal.oobCode = "";

        this.generalService.setCartPanelState = false;
        this.generalService.setModalState = {
          id: "2",
          name: "signInModal",
          isOpen: true,
        };
        this.router.navigate(["/"]);
      }
    } catch (error) {
      console.log("error in handleResetPassword: ", error);
    }
  }

  acceptCookies() {
    this.afAnalytics.setAnalyticsCollectionEnabled(true);
    this.cookieService.set("cookie-consent", "true");

    this.generalService.setModalState = {
      id: "cookieModal",
      name: "cookieModal",
      isOpen: false,
    };

    initAnalytics();
  }

  declineCookies() {
    this.cookieService.deleteAll();
    this.afAnalytics.setAnalyticsCollectionEnabled(false);
    this.cookieService.set("cookie-consent", "false");

    this.generalService.setModalState = {
      id: "cookieModal",
      name: "cookieModal",
      isOpen: false,
    };

    this.router.navigate(["/"]);
  }

  updateDeliveryAddress() {
    // this.deliveryAddress.is_default = this.modal.data.is_default === 0 ? 1 : 0;
    this.modal.action(this.deliveryAddress, this.deliveryAddress);
    this.generalService.setModalState = {
      ...this.generalService.getModalState,
      isOpen: false,
    };
  }

  addDeliveryAddress() {
    this.deliveryAddress.is_default = 0;
    this.generalService.setModalState = {
      ...this.generalService.getModalState,
      data: this.deliveryAddress,
    };

    this.modal.action(this.deliveryAddress);
    // this.userService.getShippingAddresses()
    // this.addDeliveryAddress()

    // this.generalService.setModalState = {
    //   ...this.generalService.getModalState,
    //   isOpen: false
    // };
  }

  confirmatoryAction() {
    this.modal.action();
    this.generalService.setModalState = {
      ...this.generalService.getModalState,
      isOpen: false,
    };
  }

  numberChange(event, field) {
    let num = event.target.value;
    num = num.replace(/\s/g, "");
    num = num.replace(/\D+/g, "");

    if (field === "zip") {
      if (num.length && num.length > 5) {
        num = num.substring(0, 5);
      }

      this.deliveryAddress.shipping_zip = num || "";
    }

    if (field === "phone") {
      this.deliveryAddress.shipping_phone = num || "";
    }
  }

  acceptAgeConfirmation(): void {
    this.cacheService.set(
      "last-age-popup-shown-timestamp",
      new Date().getTime().toString()
    );
    this.closeModal();
    this.isBackgroundDarker = false;
  }

  validateAge(birthDate: string) {
    const today = new Date();
    const birthDateI = new Date(birthDate);
    let age = today.getFullYear() - birthDateI.getFullYear();
    const monthDiff = today.getMonth() - birthDateI.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDateI.getDate())
    ) {
      age--;
    }
    return age < 18;
  }
}
