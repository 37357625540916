import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable, Subject } from "rxjs";
import { tap, switchMap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import { CacheService } from "src/app/core/services/cache.service";
import { AuthService } from "src/app/core/services/auth.service";
import { environment } from "src/app/tenants/jaloviini/environments/environment";
import { SiteConfigService } from "src/app/core/services/site-config.service";

@Injectable()
export class InterceptorService implements HttpInterceptor {
  // defaults
  authService: AuthService;
  private refreshSubject: Subject<any> = new Subject<any>();

  /**
   * Creates an instance of InterceptorService.
   * @param {CacheService} cacheService
   * @param {ToastrService} toastr
   * @memberof InterceptorService
   */
  constructor(
    private injector: Injector,
    private cacheService: CacheService,
    private toastr: ToastrService,
    private siteConfigService: SiteConfigService
  ) {}

  /**
   * this is to intercept the http request
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof InterceptorService
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);

    if (
      // localization request intercept.
      request.url.search("i18n") === -1 &&
      request.url.search("locale") === -1
    ) {
      // set auth headers
      request = this.addAuthHeader(request);
    }

    // creating a request instance
    request = request.clone({
      setHeaders: { "Content-Type": "application/json" },
    });

    if (request.url.endsWith("/refresh")) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      tap(
        (response) => this.handleResponse(response),
        (error) => this.handleError(request, error, next)
      )
    );
  }

  /**
   * this is to handle response
   * @param {HttpRequest<any>} request
   * @param {HttpEvent<any>} res
   * @memberof InterceptorService
   */
  private handleResponse(res: HttpEvent<any>) {
    try {
      if (res instanceof HttpResponse) {
        res = res.clone({
          body: res.body ? res.body.result : null,
        });
      }
      return res;
    } catch (error) {
      console.log("error in handleResponse: ", error);
    }
  }

  /**
   * this is to handle error
   * @param {HttpRequest<any>} request
   * @param {*} event
   * @memberof InterceptorService
   */
  private handleError(
    request: HttpRequest<any>,
    error: HttpErrorResponse,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse) {
      console.log("::Interceptor Error::", error);

      // handle token expired case
      if (this.isTokenExpiredError(error)) {
        return this.isTokenExpired().pipe(
          switchMap(() => {
            return next.handle(this.addAuthHeader(request));
          })
        );
      }
      this.toastr.error(error.error.message);
      throw error;
    }
  }

  /**
   * represents add auth headers
   * to the request object
   * @private
   * @param {HttpRequest<any>} request
   * @returns {HttpRequest<any>}
   * @memberof InterceptorService
   */
  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.cacheService.get("token", "session");
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `${token}`,
        },
      });
    }
    return request.clone({
      url: environment.baseUrl + request.url,
      setHeaders: {
        "API-PASSWORD": `${environment.svAPIKey}`,
        LOCALE: this.siteConfigService.siteConfig.defaultLanguage || "en",
      },
    });
  }

  private isTokenExpired() {
    try {
      this.refreshSubject.subscribe({
        complete: () => {
          this.refreshSubject = new Subject<any>();
        },
      });
      if (this.refreshSubject.observers.length === 1) {
        this.authService.getRefreshToken().subscribe(this.refreshSubject);
      }
      return this.refreshSubject;
    } catch (error) {
      console.log("error isTokenExpired: ", error);
    }
  }

  /**
   * represents check if error
   * is for expired token
   * @private
   * @param {HttpErrorResponse} error
   * @returns {boolean}
   * @memberof InterceptorService
   */
  private isTokenExpiredError(error: HttpErrorResponse): boolean {
    return (
      error.status &&
      error.status === 401 &&
      error.error &&
      error.error.message === "Token has expired"
    );
  }
}
